import {initializePaddle} from '@paddle/paddle-js';

export default function({ app, $config, $auth }, inject) {
  if (!$config.paddleToken) return

  if (!app.mixins) app.mixins = []
  app.mixins.push({
    watch: {
      '$consent.cookies.analytics': {
        async handler(newVal) {
          if (newVal) {
            await init();
          }
        },
        immediate: true
      }
    },
  })
  if ($auth) {
    app.mixins.push({
      data() {
        return {
          currentPaddleUser: null,
        }
      },
      methods: {
        identifyPaddleUser(user) {
          if (!this.$paddle) return
          this.currentPaddleUser = user
          this.$paddle.Update({
            pwCustomer: {
              email: user.email
            }
          })
        }
      },
      async mounted() {
        await init()
        this.$nextTick(() => {
          if (this.$auth?.user) {
            this.identifyPaddleUser(this.$auth.user)
          } else {
            this.$store.watch((state) => {
                if (state.auth.user && !this.currentPaddleUser) {
                  this.identifyPaddleUser(state.auth.user)
                }
              },
              (_) => {
              },
              {
                deep: true
              }
            );
          }
        })
      },
    })
  }

  async function init() {
    if (!process.client || !app?.$consent?.cookies?.marketing) {
      return;
    }
    const paddle = await initializePaddle({
      environment: $config.paddleEnv,
      token: $config.paddleToken,
    })
    inject('paddle', paddle)
  }

}
