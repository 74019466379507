import Vue from "vue";
import Segment from '@dansmaculotte/vue-segment'

export default function (context, inject) {
  const {$auth, app, store, $config} = context

  const dummySegment = {
    track: () => {
    },
    identify: () => {
    },
    page: () => {
    },
    reset: () => {
    },
  };

  inject('analytics', dummySegment)

  if (!$auth) {
    return
  }

  if (!app.mixins) app.mixins = []
  app.mixins.push({
    watch: {
      '$consent.cookies.analytics': {
        handler(newVal) {
          if (newVal) {
            init();
          }
        },
        immediate: true
      }
    },
  })

  app.mixins.push({
    data() {
      return {
        currentUser: null,
      }
    },
    methods: {
      identifyUser(user) {
        if (!this.$analytics) return
        this.currentUser = user
        this.$analytics.identify(user.email, {
          email: user.email,
          name: user.name,
          subscription: user?.subscription?.slug ?? 'free',
        })
        if (window?.tolt_referral) {
          window.tolt.signup(user.email)
        }
      }
    },
    mounted() {
      this.$nextTick(() => {
        if (this.$auth?.user) {
          this.identifyUser(this.$auth.user)
        } else {
          this.$store.watch((state) => {
              if (state.auth.user && !this.currentUser) {
                this.identifyUser(state.auth.user)
              }
            },
            (_) => {
            },
            {
              deep: true
            }
          );
        }
      })
    },
  })

  function init(){
     // removed segment for now
  }
}


